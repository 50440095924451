import React from 'react'
import Layout from "../components/shared/layout";
import SEO from "../components/shared/seo";
import tw from 'tailwind.macro';
import styled from 'styled-components';

const SharePage = () => {

  return ( 
    <Layout>
      <SEO title="Roadmap" />
      
      <Container>
        <PageTitle>MAKE101 - Roadmap</PageTitle>
        <PageContent>
          <p>MAKE101 is a project by Urbanlink/Arn van der Pluijm. The goal is to share as soon as possible with others what you would like to create. This stimulates, inspires and motivates you and others to actually start making stuff! </p>
          <p>There is a lot of room for further development, this page show a list of the tasks ahead.</p>
          <ul>
            <li>Send a weekly update by mail to each user</li>
            <li>Create a ToMake Item page (/list/[user]/[item]</li>
            <li>Add an item description</li>
            <li>View lists of others</li>
            <li>Add comments to an item (by any user)</li>
            <li>Put an item af another user on your own list</li>
            <li>Share your list or an item on social media</li>
            <li>Create a final comment and proof of work after finishing an item</li>
            <li>Categorize items using categories and/or tags</li>
            <li>'Star' items by others</li>
          </ul>
          <p>All development is open source and you can find it on <a href="https://gitlab.com/avdp/make101">Gitlab</a></p>
        </PageContent>
      </Container>
    </Layout>
  )
}

export default SharePage;

const Container = styled.section `${tw`max-w-2xl m-auto py-16`}`
const PageTitle = styled.h1 `${tw`font-bold uppercase`}`
const PageContent = styled.div`${tw`my-16 leading-normal`}`